<template>
  <div class="pageHeader pl-3 max-main">
    <ul class="breadcrumb relative w-full line-height-0 pb-10">
      <router-link tag="li" to="/properties">Properties</router-link>
      <li class="current">{{ name }}</li>
    </ul>
    <div class="relative w-full grid grid-cols-2">
      <h1 class="text-30 text-white col-span-1">{{ name }}</h1>
      <p class="text-12 text-ltgrey col-span-1 text-right">{{ address }}</p>
    </div>
    <div class="info flex flex-row">
      <div class="info">
        <div class="grid grid-cols-5 gap-3 text-left text-ltgrey text-12">
          <div>
            {{ roomLabel.toUpperCase() }}S: <span class="font-extrabold">{{ units }}</span>
          </div>
          <div>
            APPLIANCES: <span class="font-extrabold">{{ applianceCounts.total }}</span>
          </div>
          <div>
            ONLINE: <span class="font-extrabold text-green">{{ applianceCounts.online }}</span>
          </div>
          <div>
            OFFLINE: <span class="font-extrabold text-yellow">{{ applianceCounts.offline }}</span>
          </div>
          <div>
            ERRORS: <span class="font-extrabold text-red">{{ applianceCounts.errors }}</span>
          </div>
        </div>
      </div>
      <div class="flex-grow text-12 text-white text-right">
        <span class="align-baseline">{{ managerEmail }}</span>
      </div>
    </div>
    <div class="info relative w-full py-10 flex">
      <div class="align-left text-18 flex-grow">
        <router-link tag="button" to="/properties/show/batch-command" class="yellow mr-5 mb-5"
          >Batch Command</router-link
        >
        <button class="cursor-not-allowed grey mr-5 mb-5">Filter</button>
        <button class="cursor-not-allowed grey mb-5">Manage</button>
      </div>
      <div class="align-right view flex-none">
        <svg class="float-right" width="61px" height="21px" xmlns="http://www.w3.org/2000/svg">
          <g id="gridListToggle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Floors" transform="translate(-1342.000000, -283.000000)">
              <path id="Rectangle" fill="#262524" d="M0 0H1440V1024H0z" />
              <path
                d="M1382,283 L1387,283 L1387,288 L1382,288 L1382,283 Z M1382,291 L1387,291 L1387,296 L1382,296 L1382,291 Z M1387,299 L1382,299 L1382,304 L1387,304 L1387,299 Z M1390,283 L1395,283 L1395,288 L1390,288 L1390,283 Z M1395,291 L1390,291 L1390,296 L1395,296 L1395,291 Z M1390,299 L1395,299 L1395,304 L1390,304 L1390,299 Z M1403,283 L1398,283 L1398,288 L1403,288 L1403,283 Z M1398,291 L1403,291 L1403,296 L1398,296 L1398,291 Z M1403,299 L1398,299 L1398,304 L1403,304 L1403,299 Z"
                id="Shape"
                fill="#979797"
              />
              <path
                d="M1345,286 L1342,286 L1342,289 L1345,289 L1345,286 Z M1345,292 L1342,292 L1342,295 L1345,295 L1345,292 Z M1342,298 L1345,298 L1345,301 L1342,301 L1342,298 Z M1366,286 L1349,286 L1349,289 L1366,289 L1366,286 Z M1349,292 L1366,292 L1366,295 L1349,295 L1349,292 Z M1366,298 L1349,298 L1349,301 L1366,301 L1366,298 Z"
                id="Shape"
                fill="#454541"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: 'FLOOR 1',
      },
      address: {
        type: String,
        default: '123 Rooms',
      },
      managerName: {
        type: String,
        default: 'First Last',
      },
      managerEmail: {
        type: String,
        default: 'name@email.com',
      },
      url: {
        type: String,
        default: '/properties/show/building-1/floor-1',
      },
      units: {
        type: Number,
        default: 10,
      },
      applianceCounts: {
        type: Object,
        default: () => ({
          total: 0,
          online: 0,
          offline: 0,
          errors: 0,
        }),
      },
    },
    components: {},
    data() {
      return {};
    },
    computed: {
      roomLabel: function() {
        return this.$store.getters.propertyLabels('room');
      },
      floorAddress: function() {
        return `${this.units} ${this.roomLabel}`;
      },
    },
    mounted() {},
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
  .breadcrumb {
    li {
      cursor: pointer;
    }
  }
</style>
