<template>
  <div class="cursor-pointer mb-5 mr-5">
    <svg
      version="1.1"
      id="propertiesFloorBlock"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="242px"
      height="261px"
      xml:space="preserve"
      class="cursor-pointer"
      @click="blockClick()"
    >
      <path
        id="Rectangle-Copy-3"
        d="M10,0h222c5.5,0,10,4.5,10,10v241c0,5.5-4.5,10-10,10H10c-5.5,0-10-4.5-10-10V10	C0,4.5,4.5,0,10,0z"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#2d2d2a"
      />
      <path id="Rectangle-Copy-10" class="st1" d="M6 182H232V184H6z" />
      <path id="Rectangle-Copy-11" class="st1" d="M6 112H232V114H6z" />
      <text transform="matrix(1 0 0 1 89.558 84.296)" class="ltgrey st3 st4">
        Online:
      </text>
      <text transform="matrix(1 0 0 1 90.0001 98.7839)" class="font-extrabold green st6 st7">
        {{ applianceCounts.occupied.online }}
      </text>
      <text transform="matrix(1 0 0 1 89.558 154.296)" class="ltgrey st3 st4">
        Online:
      </text>
      <text transform="matrix(1 0 0 1 90.0001 168.784)" class="font-extrabold green st6 st7">
        {{ applianceCounts.vacant.online }}
      </text>
      <text transform="matrix(1 0 0 1 89.558 224.296)" class="ltgrey st3 st4">
        Online:
      </text>
      <text transform="matrix(1 0 0 1 90.0001 238.784)" class="font-extrabold green st6 st7">
        {{ applianceCounts.cleaning.online }}
      </text>
      <text transform="matrix(1 0 0 1 144.558 84.296)" class="ltgrey st3 st4">
        Offline:
      </text>
      <text transform="matrix(1 0 0 1 145 98.7839)" class="font-extrabold yellow st6 st7">
        {{ applianceCounts.occupied.offline }}
      </text>
      <text transform="matrix(1 0 0 1 144.558 154.296)" class="ltgrey st3 st4">
        Offline:
      </text>
      <text transform="matrix(1 0 0 1 145 168.784)" class="font-extrabold yellow st6 st7">
        {{ applianceCounts.vacant.offline }}
      </text>
      <text transform="matrix(1 0 0 1 144.558 224.296)" class="ltgrey st3 st4">
        Offline:
      </text>
      <text transform="matrix(1 0 0 1 145 238.784)" class="font-extrabold yellow st6 st7">
        {{ applianceCounts.cleaning.offline }}
      </text>
      <text transform="matrix(1 0 0 1 196.558 84.296)" class="ltgrey st3 st4">
        Errors:
      </text>
      <text transform="matrix(1 0 0 1 197 98.7839)" class="font-extrabold st6 st7" fill="#c53232">
        {{ applianceCounts.occupied.errors }}
      </text>
      <text transform="matrix(1 0 0 1 196.558 154.296)" class="ltgrey st3 st4">
        Errors:
      </text>
      <text transform="matrix(1 0 0 1 197 168.784)" class="font-extrabold ltgrey st6 st7">
        {{ applianceCounts.vacant.errors }}
      </text>
      <text transform="matrix(1 0 0 1 196.558 224.296)" class="ltgrey st3 st4">
        Errors:
      </text>
      <text transform="matrix(1 0 0 1 197 238.784)" class="font-extrabold ltgrey st6 st7">
        {{ applianceCounts.cleaning.errors }}
      </text>
      <text transform="matrix(1 0 0 1 9.0001 23)" class="font-extrabold st10 st6 st7">
        {{ name }}
      </text>
      <text transform="matrix(1 0 0 1 9.0001 95)" class="font-extrabold text-white st12 st13">
        {{ applianceCounts.occupied.totalUnits }}
      </text>
      <text transform="matrix(1 0 0 1 9.0001 165)" class="font-extrabold text-white st12 st13">
        {{ applianceCounts.vacant.totalUnits }}
      </text>
      <text transform="matrix(1 0 0 1 9.0001 235)" class="font-extrabold text-white st12 st13">
        {{ applianceCounts.cleaning.totalUnits }}
      </text>
      <text transform="matrix(1 0 0 1 73.0001 23)" class="st10 st3 st14">
        {{ units }} {{ roomLabel }}s
      </text>
      <text transform="matrix(1 0 0 1 10.0001 64)" class="text-white st3 st14">
        Occupied
      </text>
      <text transform="matrix(1 0 0 1 10.0001 134)" class="text-white st3 st14">
        Vacant
      </text>
      <text transform="matrix(1 0 0 1 10.0001 204)" class="text-white st3 st14">
        Cleaning
      </text>
      <path
        id="Path"
        fill="none"
        stroke="#3d3d3d"
        stroke-linecap="square"
        d="M10 33.2L231.5 32.8"
      />
      <path
        class="st10"
        d="M226,22c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.3-1,0.1-1.4l4.2-3.8l-4.2-3.8c-0.4-0.4-0.4-1-0.1-1.4 c0.4-0.4,1-0.4,1.4-0.1l5,4.5c0.2,0.2,0.3,0.5,0.3,0.7s-0.1,0.6-0.3,0.7l-5,4.5C226.5,21.9,226.2,22,226,22z"
        id="Path_1_"
      />
    </svg>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: 'FLOOR 1',
      },
      applianceCounts: {
        type: Object,
        default: () => ({
          online: 371,
          offline: 20,
          cleaning: 2,
          errors: 1,
        }),
      },
      manager: {
        type: String,
        default: 'First Last',
      },
      units: {
        type: Number,
        default: 25,
      },
      url: {
        type: String,
        default: '/properties/show/building-1/floor-1',
      },
    },
    components: {},
    computed: {
      roomLabel: function() {
        return this.$store.getters.propertyLabels('room');
      },
      floorAddress: function() {
        return `${this.units} ${this.roomLabel}s`;
      },
      floors() {
        return this.$store.state.properties[0].buildings[0].floors;
      },
    },
    mounted() {},
    watch: {},
    methods: {
      blockClick: function() {
        if (this.url) {
          this.$router.push(this.url);
        }
      },
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';

  svg#propertiesFloorBlock {
    .text-white {
      fill: #fff;
    }
    .st1 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #262524;
    }
    // .ltgrey {
    //   line-height: 10em;
    //   fill: $ltgrey;
    // }
    .st3 {
      font-family: $sans;
    }
    .st4 {
      font-size: 10px;
    }
    .st6 {
      font-family: $sans;
    }
    .st7 {
      font-size: 14px;
    }
    .st10 {
      fill: #bbb;
    }
    .st12 {
      font-family: $sans;
    }
    .st13 {
      font-size: 24px;
    }
    .st14 {
      font-size: 12px;
    }
  }
</style>
