<template>
  <section>
    <propertiesPageHeader
      :name="property.name"
      :address="property.address"
      :units="property.units"
      :applianceCounts="property.applianceCounts"
    ></propertiesPageHeader>
    <div class="gradient_spacer relative w-full m-0"></div>
    <ul class="flex flex-wrap flex-row max-main">
      <floorlisting
        v-for="floor in floors"
        :key="floor.name"
        :name="floor.name"
        :applianceCounts="floor.applianceCounts"
        :url="floor.url"
        :units="floor.units"
      ></floorlisting>
    </ul>
  </section>
</template>

<script>
  import propertiesPageHeader from '@/components/propertiesPageHeader.vue';
  import floorlisting from '@/components/propertiesFloorBlock.vue';
  import { getDataByHostname } from '../utils/dataUtil';
  export default {
    components: { floorlisting, propertiesPageHeader },
    data() {
      return {
        property: [{ name: '', applianceCounts: {}, buildings: [] }],
      };
    },
    computed: {
      floors() {
        return this.$store.state.properties[0].buildings[0].floors;
      },
    },
    mounted() {
      const dataByHostName = getDataByHostname();
      this.property = dataByHostName?.properties?.[0] || {};
    },
    watch: {},
    methods: {},
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
  .gradient_spacer {
    border: none;
  }
</style>
